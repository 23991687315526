<template>
  <div class="home">
    <div class="invitations">
      <div class="logo">
        <img src="@/assets/images/logo_full.png" alt="">
      </div>
      <div @click="showModal(1)" class="invite">
        <img src="@/assets/images/ticket_vip.png">
      </div>
      <div @click="showModal(0)" class="invite">
        <img src="@/assets/images/ticket.png">
      </div>
      <div class="infos">
        <img src="@/assets/images/texts/text-1.png" alt="">
        <img src="@/assets/images/texts/text-2.png" alt="">
        <img src="@/assets/images/texts/text-3.png" alt="">
        <img src="@/assets/images/texts/text-4.png" alt="">
      </div>
      <div class="socials">
        <span class="social">
          <img src="@/assets/images/instagram.png">
          <a target="_blank" href="https://www.instagram.com/angeloesterabride?igsh=aTF5YnVvNnpqZmdw">
            Angelo Estera Bride
          </a>
        </span>
        <span class="social">
          <img src="@/assets/images/instagram.png">
          <a target="_blank" href="https://www.instagram.com/angeloestera?igsh=eGZkNTFkczdydnlx">Angelo Estera</a>
        </span>
        <span class="social"></span>
      </div>
    </div>

    <b-modal ref="my-modal" hide-footer hide-header centered>
      <div class="modal-payment">
        <div class="title">
          <label for="">Payment - {{ (form.type == 1) ? 'VIP' : 'Normal' }}</label>
          <span class="btn-close" role="button" @click="hideModal"></span>
        </div>

        <div class="infos" v-show="!show && !showLoading">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="12">
                <div class="mb-2">
                  <span class="title-info w-100 text-center">The transition is in progress...</span>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="infos" v-show="showLoading">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="12">
                <div class="mb-2">
                  <span class="title-info w-100 text-center">Please wait a bit... We are generating your order</span>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="infos" v-show="show && !showLoading">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="12">
                <div class="mb-2">
                  <span class="title-info">General Information</span>
                </div>
              </b-col>
              <b-col cols="6">
                <div class="group-input">
                  <label for="">First Name</label>
                  <input class="w-100" type="text" v-model="form.first_name" placeholder="Enter your first name">
                </div>
              </b-col>
              <b-col cols="6">
                <div class="group-input">
                  <label for="">Last Name</label>
                  <input class="w-100" type="text" v-model="form.last_name" placeholder="Enter your last name">
                </div>
              </b-col>
              <b-col cols="12">
                <div class="group-input">
                  <label for="">E-mail</label>
                  <input class="w-100" type="email" v-model="form.email" placeholder=" eg.: user@teste.com">
                </div>
              </b-col>
              <b-col cols="7">
                <div class="group-input">
                  <label for="">Country</label>
                  <input class="w-100" type="text" v-model="form.country" placeholder="Enter your Country">
                </div>
              </b-col>
              <b-col cols="12" v-show="showPay && show">
                <div class="mt-4">
                  <span class="title-info">Enter your credit card details to make the payment</span>
                </div>
              </b-col>
              <b-col cols="12" v-show="showPay && show">
                <div id="payment-element"></div>
              </b-col>
              <b-col cols="6">
                <div class="group-input">
                  <label for="">Value to Pay</label>
                  <input type="text" class="w-100" :value="value_text" disabled>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="group-input">
                  <label for="">Quantity</label>
                  <input v-show="!showPay && show" type="number" v-model="form.type_count" @input="calc" min="1">
                  <input type="number"  v-show="showPay && show" v-model="form.type_count" disabled>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col cols="6">
                <b-button class="w-100" variant="secondary" @click="onReset">Cancel</b-button>
              </b-col>
              <b-col cols="6">
                <b-button class="w-100" variant="success" v-show="!showPay && show" @click="create">Next</b-button>
                <b-button class="w-100" variant="success" v-show="showPay && show" @click="handleSubmit">Confirm</b-button>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'HomeView',
  data() {
    return {
      stripe: null,
      elements: null,
      paymentElement: null,
      code: '',
      value_text: '',
      form: {
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        type: 0,
        type_count: 1,
      },
      show: true,
      showPay: false,
      showLoading: false
    };
  },
  methods: {
    showAlert(type, msg) {
      let textMsg = '';

      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        confirmButtonText: 'Confirm',
        html: textMsg,
        icon: type,
      });
    },
    async create() {
      this.showLoading = true;

      this.$nextTick(async () => {
        try {
          this.stripe = await loadStripe('pk_live_51PpbWUP4YALKmoSggtdAqeb82Kna2kCUOVv5n1dg1eKFun8SgCkis00Ny4fgGzyeWQw6AytYSy5ojM5ASVmEl0Dz00JxtsjVWd');

          const response = await this.$http.post('/create-payment-intent', this.form, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const clientSecret = response.data.clientSecret;
          this.code = response.data.code;
          
          this.elements = this.stripe.elements({ clientSecret, locale: 'en' });
         
          this.showPay = true;
          this.showLoading = false;

          this.$nextTick(() => {
            if (document.getElementById('payment-element')) {
              this.paymentElement = this.elements.create('payment');
              this.paymentElement.mount('#payment-element');
            } else {
              console.error("Elemento #payment-element não encontrado no DOM.");
            }
          });
        } catch (error) {
          this.showAlert('error', 'Check that you have filled in all the details. If the error continues, contact support.');
          this.showLoading = false;
        }
      });
    },
    showModal(type) {
      this.form.type = type;
      this.$refs['my-modal'].show();
      this.show = true; 
      this.showPay = false;
      this.showLoading = false;

      this.value_text = (type == 1)? 'USD $500': 'USD $300';

      this.form.first_name = '';
      this.form.email = '';
      this.form.type_count = 1;
      
    },
    calc(){
      const value = (this.form.type == 1)? 500: 300;
      this.value_text = 'USD $'+value * this.form.type_count;
    },
    hideModal() {
      this.$refs['my-modal'].hide();
      this.show = false; 
      this.showPay = false;
      this.showLoading = false;
    },
    onNext(){

    },
    async handleSubmit() {
      this.show = false; 
      this.showLoading = false;

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        redirect: 'if_required',
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.form.name,
              email: this.form.email,
            },
          },
          metadata: {
            code: this.form.code,
          },
        },
      });

      if (error) {
        console.error('Erro ao confirmar o pagamento:', error);
        this.showAlert('error', error.message);
        this.show = true; 

      } else {
        this.showAlert('success', 'Your purchase has been made! After payment confirmation, you will receive an email with other information.');
        this.hideModal();
      }
    },
    onReset() {
      this.form.first_name = '';
      this.form.email = '';
      this.form.type = 0;
      this.form.type_count = 1;
      this.hideModal();
    },
  },
}
</script>

<style>
/* Estilos adicionais */
#payment-element {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}
</style>
