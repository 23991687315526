<template>
  <div class="home">
    <div class="invitations invitations-list">
      <div class="logo">
        <img src="@/assets/images/logo_full.png" width="300">
      </div>
      <div class="infos">
       
        <table class="table table-striped table-class" id= "table-id">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Country</th>
              <th>Type</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
            
          </thead>
          
          <tbody>
            <tr v-for="item in list" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.country }}</td>
              <td>{{ (!item.is_vip)? 'Normal' : 'VIP' }}</td>
              <td>{{ (!item.is_paid)? 'Pending' : 'Paid' }}</td>
              <td>{{ item.created_at }}</td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data() {
    return {
      list: [],
      show: true
    }
  },
  methods: {
    showAlert(type, msg) {
        var textMsg = '';

        if (msg.message) {
            if (typeof msg.errors === 'object' && msg.errors) {
                for (const key in msg.errors) {
                    if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
                    const errorMessages = msg.errors[key];
                    textMsg += `${errorMessages.join(', ')}<br>`;
                    }
                }
            } else {
                textMsg = `${msg.message}`;
            }
        } else {
            textMsg = `${msg}`;
        }

        this.$swal({
            text: textMsg,
            confirmButtonText: 'Confirm',
            html: textMsg,
            icon: type
        });
    },

    load() {
      this.show = false;
      this.$http.get('/payment', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          this.show = true;
          this.list = response.data;
        })
        .catch((error) => {
          this.show = true;
          console.error('Login Error:', error);
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'An unknown error has occurred');
          }
        });
    },
  },
  created(){
    this.load();
  }
}
</script>
